import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CuresList from './CuresList';
import { DateRangePicker } from 'react-date-range';
import { Button, Dialog } from '@material-ui/core';
import styles from '../../../styles/viewer.module.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import CuresAPI from '../../../services/CuresAPI';
import RegisterModal from './RegisterModal';
import auth from '../../../utils/auth';

const CuresViewer = () => {

  const { patientId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [curesDataAssembled, setCuresDataAssembled] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(Date.now() - (1000 * 60 * 60 * 24 * 365)),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [curesUser, setCuresUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleClose = () => setOpenDatePicker(false);

  const getCuresApiDateRange = useCallback(
    () => {
      const { startDate, endDate } = dateRange[0];
      const activityStartDate = moment(startDate).format('YYYY-MM-DD');
      let activityEndDate = moment(endDate).format('YYYY-MM-DD');
      if (activityStartDate === activityEndDate) {
        activityEndDate = undefined;
      }
      return {
        activityStartDate,
        activityEndDate,
      };
    },
    [dateRange]
  );

  const handleSubmit = () => {
    setLoading(true);
    loadCuresData();
    setOpenDatePicker(false);
  };

  // 269 days
  const constructData = useCallback(curesData => {
    const { rxHistoryResponse } = curesData?.message?.body;
    if (rxHistoryResponse?.medicationDispensed?.daysSupply) {
      const {
        drugDescription,
        drugCoded,
        quantity,
        daysSupply,
        note,
        prescriber,
        pharmacy,
        lastFillDate,
        otherMedicationDate,
        substitutions
      } = rxHistoryResponse.medicationDispensed;
      setCuresDataAssembled([
        {
          medication: `
                    ${drugDescription}, 
                    ${drugCoded?.strength?.strengthValue} 
                    ${drugCoded?.strength?.strengthForm?.code === 'CAP' ? 'Capsules' : 'Tablets'}
                    (${quantity?.value} count),
                    ${daysSupply} day supply
                `,
          note,
          prescriber: `
                    ${prescriber?.nonVeterinarian?.name?.firstName}
                    ${prescriber?.nonVeterinarian?.name?.lastName}
                `,
          pharmacy: pharmacy?.businessName,
          lastFillDate: lastFillDate?.date,
          soldDate: otherMedicationDate?.otherMedicationDate?.date,
          substitutions,
          prescriberTooltip: {
            deaNumber: prescriber?.nonVeterinarian?.identification?.DEANumber,
            npi: prescriber?.nonVeterinarian?.identification?.NPI
          },
          pharmacyTooltip: {
            ncpdpid: pharmacy?.identification?.NCPDPID,
            npi: pharmacy?.identification?.NPI,
            stateLicenseNumber: pharmacy?.identification?.stateLicenseNumber
          }
        }
      ]);
      setLoading(false);
      return;
    }
    if (rxHistoryResponse?.medicationDispensed?.length) {
      setCuresDataAssembled(
        rxHistoryResponse?.medicationDispensed.map(drugInfo => {
          const {
            drugDescription,
            drugCoded,
            quantity,
            daysSupply,
            note,
            prescriber,
            pharmacy,
            lastFillDate,
            otherMedicationDate,
            substitutions
          } = drugInfo;
          return {
            medication: `
                            ${drugDescription}, 
                            ${drugCoded?.strength?.strengthValue} 
                            ${drugCoded?.strength?.strengthForm?.code === 'CAP' ? 'Capsules'
              : 'Tablets'}
                            (${quantity?.value} count),
                            ${daysSupply} day supply
                        `,
            note,
            prescriber: `
                            ${prescriber?.nonVeterinarian?.name?.firstName}
                            ${prescriber?.nonVeterinarian?.name?.lastName}
                        `,
            pharmacy: pharmacy?.businessName,
            lastFillDate: lastFillDate?.date,
            soldDate: otherMedicationDate?.otherMedicationDate?.date,
            substitutions,
            prescriberTooltip: {
              deaNumber: prescriber?.nonVeterinarian?.identification?.DEANumber,
              npi: prescriber?.nonVeterinarian?.identification?.NPI
            },
            pharmacyTooltip: {
              ncpdpid: pharmacy?.identification?.NCPDPID,
              npi: pharmacy?.identification?.NPI,
              stateLicenseNumber: pharmacy?.identification?.stateLicenseNumber
            }
          };
        })
      );
      setLoading(false);
    }
  }, []);

  const loadCuresData = useCallback(() => {
    setLoading(true);
    setError(null);
    const userName = auth.username;
    CuresAPI.getCuresUser(userName)
      .then(curesUser => {
        setCuresUser(curesUser);
        if (curesUser.hasRequiredIDs()) {
          const { activityStartDate, activityEndDate } = getCuresApiDateRange();
          curesUser.getMedicationInfo(patientId, activityStartDate, activityEndDate)
            .then(response => constructData(response.data))
            .catch(err => {
              setCuresDataAssembled([]);
              setLoading(false);
              setError(err.message);
            });
        }
      })
      .catch(error => {
        setLoading(false);
        setCuresDataAssembled([]);
        if (error.userNotFound || error.userIncomplete) {
          if (error.userIncomplete) {
            setCuresUser(error.user);
          }
          setModalOpen(true);
        }
        else {
          setError(error.message);
        }
      });
  }, [constructData, patientId, getCuresApiDateRange]);

  const onUserSaved = async (savedUser) => {
    setModalOpen(false);
    setOpenDatePicker(false);
    setCuresUser(savedUser);
    loadCuresData();
  }

  useEffect(() => loadCuresData(), [loadCuresData]);

  return (
    <div className={styles.curesContainer}>
      <Dialog open={openDatePicker} onClose={handleClose}>
        <DateRangePicker onChange={item => setDateRange([item.selection])}
          showSelectionPreview={true} moveRangeOnFirstSelection={false} months={1}
          ranges={dateRange} direction="horizontal"/>
        <div className={styles.buttonGroup}>
          <Button onClick={handleClose} variant="contained" color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">Confirm</Button>
        </div>
      </Dialog>
      {
        !error && !loading &&
        <div className={styles.periodSelector}>
          <p>Period: {moment(dateRange[0].startDate).format('MMM D, YYYY')} - {moment(
            dateRange[0].endDate).format('MMM D, YYYY')}</p>
          <FontAwesomeIcon icon={faCalendar} className={styles.calendarIcon}
            onClick={() => setOpenDatePicker(true)}/>
        </div>
      }
      {
        error && getFormattedError(error, curesUser)
      }
      <CuresList data={curesDataAssembled} patientId={patientId} loading={loading}/>
      {!loading && <div className={styles.curesUpdateButton}>
        <Button onClick={
          () => {
            CuresAPI.dumpTrace();
            setModalOpen(true);
          }
        }>Edit CURES profile</Button></div>}
      <RegisterModal userData={curesUser} onUserSaved={onUserSaved} open={modalOpen}
        setOpen={setModalOpen}/>
    </div>
  );
};

function getFormattedError(rawErrorMessage, curesUser) {
  const message = getFriendlyErrorMessage(rawErrorMessage, curesUser);
  return message
    ? (
      <p className={styles.curesError}>
        {getFriendlyErrorMessage(rawErrorMessage, curesUser)}
      </p>
    )
    : null;
}

function getFriendlyErrorMessage(rawErrorMessage, curesUser) {
  if (rawErrorMessage.includes(`credentials do not match any CURES account`)) {
    return [
      `${curesUser.providerType} credentials do not match any CURES account, `,
      `please update them with the "EDIT CURES PROFILE" button at the bottom.`
    ]
      .join('');
  }
  if (rawErrorMessage.toUpperCase().includes('STATUS: 400')) {
    return '';
  }
  return rawErrorMessage;
}

export default CuresViewer
