import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import 'moment/min/locales';
import { Container, Grid } from '@material-ui/core';
import Layout from '../../Layout';
import '../../../styles/index.css';
import styles from '../../../styles/viewer.module.css';
import { mockReferrals } from '../../../utils/constants';
import MockReferralsAPI from '../../../services/MockReferralsAPI';
import ReferralsAPI from '../../../services/ReferralsAPI';
import PatientData from '../PatientData';
import ReferralData from './ReferralData';
import ReferralDetailTable from './ReferralDetailTable';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReferralNotes from './ReferralNotes';
import TextDialog from './TextDialog';
import { ReferralActionButton, ReferralActions } from './ReferralActions';
import ReferralStates, {
  isReferredOrganization,
  isReferringOrganization
} from '../../../services/ReferralStates';
import { patientDataStore } from '../../../store/PatientDataStore';
import { PatientProvisioningDialog } from '../PatientProvisioningDialog';
import auth from '../../../utils/auth';
import { convertReferralToDisplayPatient } from '../../../utils';
import ConfirmationDialog from './ConfirmationDialog';
import { NotFoundException } from '../../../utils/exceptions';
import ReferralDocumentSelectionDialogContainer
  from './ReferralDocumentSelectionDialogContainer';
import { ReferralUtils } from '../../../services';
import ReferralDocuments from './ReferralDocuments';

const API = mockReferrals ? MockReferralsAPI : ReferralsAPI;

const ReferralDetail = () => {
  const history = useHistory()
  const { patientId, referralId } = useParams();
  const [patientInfo, setPatientInfo] = useState(null);
  const [referral, setReferral] = useState(null);
  const [error, setError] = useState('');
  const [rejectCommentDialogOpen, setRejectCommentDialogOpen] = useState(false);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dialogPatientData, setDialogPatientData] = useState({});
  const [isProvisionDialogOpen, setProvisionDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Add Referral Patient to your Organization?');
  const [dialogShowOnlyOkButton, setDialogShowOnlyOkButton] = useState(false);
  const [isWithdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const [isReferralDocumentDialogOpen, setReferralDocumentDialogOpen] = useState(false);

  const loadReferral = useCallback(
    () => {
      return new Promise(async (resolve) => {
        const { referral, documents } =
          await ReferralUtils.getReferralWithDocuments(patientId, referralId)
        ;
        resolve({
          ...referral,
          get linkedDocuments() {
            return ReferralUtils.findLinkedDocuments(documents, referral)
          }
        });
      });
    },
    [patientId, referralId]
  );

  const getReferralPatientId = (referral) => {
    if (isReferringOrganization(auth, referral)) {
      return referral.referringPatientId;
    }
    if (isReferredOrganization(auth, referral)) {
      return referral.receivingPatientId;
    }
    return null;
  }

  const showProvisionDialogIfApplicable = useCallback(
    (
      dialogReferral = referral,
      dialogPatentInfo = patientInfo
    ) => {
      const isOpenIdUser = auth.loggedInUser?.['auth_type'] === 'OIDC';
      if (!dialogReferral.receivingPatientId) {
        if (!isOpenIdUser) {
          setDialogTitle([
              `Can't post Patient `,
              `to the originating system. Please use the following information`,
              `to register it manually:`
            ]
              .join(' ')
          )
        }
        setDialogShowOnlyOkButton(!isOpenIdUser);
        setDialogPatientData(dialogPatentInfo);
        setProvisionDialogOpen(true);
      }
    },
    [patientInfo, referral]
  );

  useEffect(
    () => {
      const loadData = async () => {
        let _referral;
        try {
          _referral = await loadReferral()
          setReferral(_referral);
        }
        catch (error) {
          setError(error.message);
        }
        if (_referral) {
          try {
            let _patientInfo = await patientDataStore.getPatientData(
              patientId || getReferralPatientId(_referral)
            );
            if (!_patientInfo) {
              _referral = await ReferralsAPI.getReferral(_referral.referralId, true)
              _patientInfo = convertReferralToDisplayPatient(_referral);
              setReferral(_referral);
              showProvisionDialogIfApplicable(_referral, _patientInfo);
            }
            setPatientInfo(_patientInfo);
          }
          catch (error) {
            if (!(error instanceof NotFoundException)) {
              setError(error.message);
            }
          }
          finally {
            setDataLoaded(true);
          }
        }
      };
      loadData();
    },
    [patientId, referralId, loadReferral, showProvisionDialogIfApplicable]
  );

  const openNoteDialog = () => {
    setNoteDialogOpen(true);
  };

  const closeNoteDialog = () => {
    setNoteDialogOpen(false);
  };

  const addNote = async (noteText) => {
    try {
      await API.addNote(referralId, noteText);
      closeNoteDialog();
      setReferral(await loadReferral());
    }
    catch (error) {
      return error.message;
    }
  };

  const acceptReferral = async () => {
    try {
      await API.acceptReferral(referralId);
      setReferral(await loadReferral());
    }
    catch (error) {
      setError(error.message);
    }
  };

  const addressReferral = async () => {
    try {
      await API.addressReferral(referralId);
      setReferral(await loadReferral());
    }
    catch (error) {
      setError(error.message);
    }
  };

  const completeReferral = async () => {
    try {
      await API.completeReferral(referralId);
      setReferral(await loadReferral());
    }
    catch (error) {
      setError(error.message);
    }
  };

  const showReferralRejectCommentDialog = () => {
    setRejectCommentDialogOpen(true);
  };

  const closeReferralRejectCommentDialog = () => {
    setRejectCommentDialogOpen(false);
  };

  const rejectReferral = async (comment) => {
    try {
      await API.rejectReferral(referralId, comment);
      setReferral(await loadReferral());
      closeReferralRejectCommentDialog();
    }
    catch (error) {
      return error.message;
    }
  };

  const withdrawReferral = async () => {
    try {
      await API.withdrawReferral(referralId);
      if (patientId) {
        history.push(`/patient/search/${patientId}/info/1/referrals`);
      }
      else {
        history.push(`/referrals`);
      }
      closeWithdrawDialog();
    }
    catch (error) {
      return error.message;
    }
  };

  const handleProvisionDialogSave = async (patientData) => {
    try {
      const { patientID, referral: provisionedReferral } =
        await ReferralsAPI.provisionReferralPatient(auth, referral)
      ;
      patientData.id = patientID;
      setPatientInfo({...patientData});
      setReferral(provisionedReferral);
      closeProvisionDialog();
    }
    catch (error) {
      return error.message;
    }
  }

  const closeProvisionDialog = () => {
    setProvisionDialogOpen(false);
  }

  const closeWithdrawDialog = () => {
    setWithdrawDialogOpen(false);
  }

  const showWithdrawDialog = () => {
    setWithdrawDialogOpen(true);
  }

  const showReferralDocumentsDialog = () => {
    setReferralDocumentDialogOpen(true);
  }

  const hideReferralDocumentsDialog = () => {
    setReferralDocumentDialogOpen(false);
  }

  const handleDocumentLinked = async (referral) => {
    setReferral(await loadReferral());
  }

  const hasNotes = dataLoaded && referral?.notes?.length > 0;
  const hasDocuments = referral?.linkedDocuments?.length > 0;

  return (
    <Fragment>
      <TextDialog
        open={noteDialogOpen} title="Add Note" handleSave={addNote}
        handleClose={closeNoteDialog}
      />
      <TextDialog
        open={rejectCommentDialogOpen} title="Rejection Comment"
        handleSave={rejectReferral} handleClose={closeReferralRejectCommentDialog}
      />
      <PatientProvisioningDialog
        patientData={dialogPatientData}
        open={isProvisionDialogOpen}
        title={dialogTitle}
        handleSave={handleProvisionDialogSave}
        handleClose={closeProvisionDialog}
        showOnlyOkButton={dialogShowOnlyOkButton}
      />
      <ConfirmationDialog
        open={isWithdrawDialogOpen}
        title="Withdraw Referral?"
        text="The referral will be deleted. This action cannot be reversed. Are you sure?"
        handleSave={withdrawReferral}
        handleClose={closeWithdrawDialog}
      />
      {
        isReferralDocumentDialogOpen &&
        <ReferralDocumentSelectionDialogContainer
          open={isReferralDocumentDialogOpen}
          handleSave={handleDocumentLinked}
          handleClose={hideReferralDocumentsDialog}
          patientId={patientId}
          referralId={referralId}
          referral={referral}
        />
      }
      <Layout referral={true} patientData={patientInfo}>
        <Grid item xs={12}>
          <Container className="container-documents">
            <div className={styles.documentViewer}>
              {
                error &&
                <ThemeProvider theme={theme}>
                  <Container className={styles.errorContainer}>
                    {error}
                  </Container>
                </ThemeProvider>
              }
              {
                !error && dataLoaded
                && (
                  <Fragment>
                    <PatientData patientData={patientInfo}/>
                    <ReferralActions>
                      <ReferralActionButton
                        text="Add Note" action={openNoteDialog}
                        canShow={ReferralStates.canAddNote} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Withdraw Referral" action={showWithdrawDialog}
                        canShow={ReferralStates.canWithdraw} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Accept Referral" action={acceptReferral}
                        canShow={ReferralStates.canAccept} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Address Referral" action={addressReferral}
                        canShow={ReferralStates.canAddress} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Complete Referral" action={completeReferral}
                        canShow={ReferralStates.canComplete} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Reject Referral" action={showReferralRejectCommentDialog}
                        canShow={ReferralStates.canReject} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Add Document"
                        action={showReferralDocumentsDialog}
                        canShow={ReferralStates.canAddDocument} referral={referral}
                        patientInfo={patientInfo}
                      />
                      <ReferralActionButton
                        text="Add Patient to your Org"
                        action={showProvisionDialogIfApplicable}
                        canShow={ReferralStates.canProvision} referral={referral}
                        color="secondary"
                        patientInfo={patientInfo}
                      />
                    </ReferralActions>
                    <ReferralData referral={referral}/>
                    <ReferralDetailTable referral={referral}/>
                    {
                      hasNotes &&
                      <ReferralNotes notes={referral.notes} />
                    }
                    {
                      hasDocuments &&
                      <ReferralDocuments referral={referral} patientId={patientId} />
                    }
                  </Fragment>
                )
              }
              {
                !error && !dataLoaded
                && (
                  <ThemeProvider theme={theme}>
                    <div>
                      <CircularProgress/>
                    </div>
                  </ThemeProvider>
                )
              }
            </div>
          </Container>
        </Grid>
      </Layout>
    </Fragment>
  );
};

export default withRouter(ReferralDetail);
