import {
  organizationPairsUrl,
  organizationsUrl,
  referralsFormApiUrl,
  referralsUrl,
  serviceCategoriesUrl,
} from '../utils/constants';
import { get, sortBy } from 'lodash';
import ReferralStates from './ReferralStates';
import { ErrorUtils } from '../utils/exceptions';
import { ReferralUtils } from './ReferralUtils';
import { AxiosHelper } from '../utils/AxiosHelper';

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(AxiosHelper.newInstance());

export default class ReferralsAPI {

  static async getReferrals(patientId, status, modifiedSince, entireOrganization) {
    const result = await axiosInstance.get(
      referralsUrl,
      {
        params: {
          patientId,
          status,
          modifiedSince,
          entireOrganization
        }
      }
    );
    return ErrorUtils.toEmptyArray(result);
  }

  static async getReferral(referralId, fullDemographics) {
    const result = await axiosInstance.get(
      `${referralsUrl}/${referralId}/detail${fullDemographics ? '?fullDemographics=true' : ''}`
    );
    return result?.data;
  }

  static async addNote(referralId, noteText) {
    const result = await axiosInstance.post(
      `${referralsUrl}/${referralId}/notes`,
      {
        note: noteText
      },
    )
    return result?.data;
  }

  static async linkDocument(referralId, fhirDocument) {
    const result = await axiosInstance.post(
      `${referralsUrl}/${referralId}/document`,
      {
        retrievalParameters: ReferralUtils.getRetrievalParameters(fhirDocument)
      },
    )
    return result?.data;
  }

  static async createReferral(referral) {
    const result = await axiosInstance.post(
      referralsUrl,
      referral,
    )
    return result?.data;
  }

  static async createPreventionLinkReferral(patientId, preventionLinkForm) {
    const result = await axiosInstance.post(
      `${referralsUrl}/prevention-link?patientId=${patientId}`,
      preventionLinkForm,
    )
    return result?.data;
  };

  static async getServiceCategories(auth) {
    return sortBy(
      ErrorUtils.toEmptyArray(await axiosInstance.get(`${serviceCategoriesUrl}/${auth.orgId}`))
        .filter(category => category.description !== '')
        .map(category => category.referralServiceCategory)
      , (category) => category.toUpperCase()
    );
  }

  static async getOrganizations(auth) {
    const validReceivingOrganizationIds =
      ErrorUtils.toEmptyArray(await axiosInstance.get(`${organizationPairsUrl}/${auth.orgId}`))
        .map(orgPair => orgPair.receivingAuthUniversalId)
    ;
    return sortBy(
      ErrorUtils.toEmptyArray(await axiosInstance.get(organizationsUrl))
        .filter(organization => validReceivingOrganizationIds.includes(organization.authUniversalId))
        .map(organization => organization.organizationName)
      , (organization) => organization.toUpperCase()
    );
  }

  static async acceptReferral(referralId) {
    await axiosInstance.put(`${referralsUrl}/${referralId}`, { status: ReferralStates.RECEIVED });
  }

  static async rejectReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/reject`, { comment });
  }

  static async withdrawReferral(referralId) {
    await axiosInstance.delete(`${referralsUrl}/${referralId}`);
  }

  static async addressReferral(referralId) {
    await axiosInstance.put(`${referralsUrl}/${referralId}`, { status: ReferralStates.ADDRESSED });
  }

  static async completeReferral(referralId) {
    await axiosInstance.put(`${referralsUrl}/${referralId}`, { status: ReferralStates.COMPLETED });
  }

  static async provisionUser(auth) {
    console.log("ReferralsAPI.provision()")
    if (!auth.provisioned) {
      const result = await axiosInstance.put(`${referralsUrl}/${auth.username}/provision`);
      auth.orgId = get(result, 'data.authUniversalId');
      auth.mintUserId = get(result, 'data.mintUserId');
      auth.provisioned = true;
    }
  }

  static async provisionReferralPatient(auth, referral) {
    const result = await axiosInstance.put(
      `${referralsUrl}/${auth.username}/${referral.referralId}/provision`,
      referral
    );
    return result?.data;
  }

  static async getReferralFormSize(formUUID) {
    const result = await axiosInstance.get(
      `${referralsFormApiUrl}/size/${formUUID}`
    )
    return result?.data;
  }
}
