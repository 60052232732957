import * as Sentry from '@sentry/react';
import auth from '../utils/auth';
import {
  basicLogin,
  checkUsername,
  mintProxyUrl,
  sessionInfoUrl,
  shouldLogout as shouldLogoutUrl,
} from '../utils/constants';
import { ErrorUtils } from '../utils/exceptions';
import { CatalogUtil } from '../utils';
import { get } from 'lodash';
import { AxiosHelper } from '../utils/AxiosHelper';

let axiosInstance = ErrorUtils.configureAxiosForMintErrors(AxiosHelper.newInstance());

export const validateUsername = async ({ username }) => {

  try {
    return await axiosInstance({
      url: checkUsername,
      method: 'POST',
      data: {
        username: username
      }
    })
    .then(response => {
      return response.data
    })
  } catch(error) {
    if (error && error.response && error.response.data) {
      const { message } = error.response.data;
      Sentry.captureMessage(message)
      return { error: message }
    }
  }
}

export const handleLogin = async ({
  username,
  password
}) => {
  try {
    return await axiosInstance({
      url: basicLogin,
      method: 'POST',
      data: {
        username: username,
        password: password
      }
    })
    .then(response => {
      return response.data
    })
  } catch(error) {
    if (error && error.response && error.response.data) {
      const { message } = error.response.data;
      Sentry.captureMessage(message)
      return { error: message }
    }
  }
}

export const provisionPatient = async (patient, mrn) => {
  try {
     const result = await axiosInstance({
       url: `${mintProxyUrl}/api/Patient/provision`,
       method: 'PUT',
       data: {
         fhirPatient: patient,
         mrn
       },
     });
     return result.data;
  }
  catch(error) {
    console.error('[Provision Patient Service] ', error)
    const message = error.response?.data?.message || error.message;
    Sentry.captureMessage(message)
    throw error;
  }
}

export const doesOrganizationUseManualMrnProvisioning = async () => {
  const result = await axiosInstance({
    url: [
      `${mintProxyUrl}/api/Patient/provision/manual-mrn-for-organization/`,
      get(auth, 'loggedInUser.userinfo.orgName')
    ]
      .join('')
    ,
    method: 'GET',
  });
  return result?.data;
}

export const getPatients = async (filters) => {
  //let url = `${mintProxyUrl}/api/Patient?family=${filters.lastName}`
  let url = `${mintProxyUrl}/api/Patient?`

  if(filters.patientId) {
    url = url + '_id=' + filters.patientId
  }

  if(filters.lastName) {
    url = url + '&family=' + filters.lastName
  }

  if(filters.firstName) {
    url = url + '&given=' + filters.firstName
  }

  if(filters.birthDate) {
    url = url + '&birthDate=' + filters.birthDate
  }

  try {
    const patients = await axiosInstance({
      url: url,
      method: 'GET',
      data: {}
    })
    .then(response => {
      return response.data
    })

    return patients.entry || []
  } catch(error) {
    console.error('[Patient List Service] ', error)
    const message = error.response?.data?.message || error.message;
    Sentry.captureMessage(message)
    return error.response?.status === 404 ? [] : { error: message };
  }
}

export const getDocuments = async ({patientId}) => {
  try{
    const url = mintProxyUrl + '/api/Patient/' +  patientId + '/DocumentReference'
    const docs = await axiosInstance({
      url: url,
      method: 'GET',
      data: {}
    })
    .then(response => {
      return response.data
    })

    return docs.entry
  } catch(error) {
    console.error('[Documents Data Service] ', error)
    const message = error.response?.data?.message || error.message;
    Sentry.captureMessage(message)
    return error.response?.status === 404 ? [] : { error: message };
  }
}

export const getDocument = async ({docReferenceId, rawCCD=false}) => {
  try{
    return await axiosInstance({
      url: `${mintProxyUrl}/api/DocumentReference`,
      method: 'GET',
      params: {
        _id: docReferenceId,
        ...{ rawCCD: rawCCD || undefined }
      }
    })
    .then(response => {
      return response.data
    })
  } catch(error) {
    console.error('[Document Data Service] ', error)
    const message = get(error, 'error.response.data.message', error.message);
    Sentry.captureMessage(message);
    return { error: message };
  }
}

export const getDocumentType = async ({code}) => {
  try{
    return await axiosInstance({
      url: `${mintProxyUrl}/api/ValueSet`,
      method: 'GET',
      params: {
        code: code,
        groupName: 'document-type'
      }
    })
    .then(response => {
      return response.data
    })
  } catch(e) {
    console.error('[Document Type Service] ', e)
    Sentry.captureMessage(e)
    return []
  }
}

export const getTemplate = async ({ fhirDocument }) => {
  const result = await axiosInstance({
    url: `${mintProxyUrl}/api/DocumentReference/template`,
    method: 'POST',
    data: fhirDocument
  });
  return result?.data;
}

export const uploadDocument = async ({
  patientID, fhirDocument, file, onUploadProgress
}) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append('patientID', patientID);
  formData.append('fhirDocument', JSON.stringify(fhirDocument));
  return axiosInstance.post(
    `${mintProxyUrl}/api/DocumentReference/upload`,
    formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
};

export const addPatientDocument = async (patientID, fhirDocument) => {
  const result = await axiosInstance({
    url: `${mintProxyUrl}/api/Patient/${patientID}/DocumentReference`,
    method: 'POST',
    data: fhirDocument
  });
  return result?.data;
}

export const getPatientData = async (patientId) => {
  const result = await axiosInstance({
    url: `${mintProxyUrl}/api/Patient?_id=${patientId}`,
    method: 'GET',
  })
  return result?.data?.entry;
}

export const getDocumentCatalogs = async() => {
  const result = await axiosInstance({
    url: `${mintProxyUrl}/api/DocumentReference/catalogs`,
    method: 'GET',
  });
  return CatalogUtil.normalizeCatalogs(result?.data);
}

export const getSessionInfo = async() => {
  const result = await axiosInstance({
    url: sessionInfoUrl,
    method: 'GET',
  });
  return result?.data;
}

export const shouldLogout = async(token) => {
  const result = await axiosInstance({
    url: shouldLogoutUrl,
    method: 'GET',
    params: {
      token,
    }
  })
  return result?.data;
}

export default getPatients
