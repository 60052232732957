const mintProxyPortAndHost = process.env.REACT_APP_MINT_PROXY_HOST;
export const referralsFormApiUrl = process.env.REACT_APP_FORM_API_URL;
export const referralsFormRendererUrl = process.env.REACT_APP_FORM_RENDERER_URL;

export const checkUsername = `${mintProxyPortAndHost}/api/auth/username`;
export const shouldLogout = `${mintProxyPortAndHost}/api/auth/shouldLogout`;
export const login = `${mintProxyPortAndHost}/api/auth/login`;
export const basicLogin = `${mintProxyPortAndHost}/api/auth/basic/login`;
export const sessionInfoUrl = `${mintProxyPortAndHost}/api/auth/sessionInfo`;
export const userHistoryUrl = `${mintProxyPortAndHost}/api/users-history`;
export const patientsHistoryUrl = `${userHistoryUrl}/patients`;
export const referralsUrl = `${mintProxyPortAndHost}/api/Patient/referrals`;
export const usersUrl = `${mintProxyPortAndHost}/api/users`;
export const serviceCategoriesUrl = `${referralsUrl}/service-categories`;
export const organizationsUrl = `${referralsUrl}/organizations`;
export const organizationPairsUrl = `${referralsUrl}/organization-pairs`;
export const getServerLastCommit = `${mintProxyPortAndHost}/api/app-info/last-commit`;
export const curesUrl = `${mintProxyPortAndHost}/api/cures`;
export const invitesUrl = `${mintProxyPortAndHost}/api/Invites`;
export const addInviteUrl = `${mintProxyPortAndHost}/api/Invites/invite`;
export const updateInviteUrl = `${mintProxyPortAndHost}/api/Invites/update-invite`;
export const confirmEmailUrl = `${mintProxyPortAndHost}/api/Invites/confirm-email`;
export const activDesactUserUrl = `${mintProxyPortAndHost}/api/Users/activate-user`;
export const updateUserUrl = `${mintProxyPortAndHost}/api/Users/update-user`;
export const resendInviteUrl = `${mintProxyPortAndHost}/api/Invites/resend-invite`;
export const expireInviteUrl = `${mintProxyPortAndHost}/api/Invites/expire-invite`;
export const modifyInviteUrl = `${mintProxyPortAndHost}/api/Invites/modify-invite`;
export const resendVerifyEmailUrl = `${mintProxyPortAndHost}/api/Invites/resend-verify-email`;
export const checkInviteExpirationUrl = `${mintProxyPortAndHost}/api/Invites/check-invite-expiration`;

export const mintProxyUrl = `${mintProxyPortAndHost}`;
export const logoutUrl = `${mintProxyPortAndHost}/api/auth/logout`;
export const fhirTypesUrl = 'https://hl7.org/fhir/valueset-c80-doc-typecodes.json';

export const heartbeatExpireSeconds = 60;
export const lastUrlKey = 'patientViewerLastUrl';

/**
 * ACTIONS
*/
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const mockData = false
export const mockReferrals = false
export const timeoutSessionMessageFlag = getBooleanValue(process.env.REACT_APP_TIMEOUT_SESSION_MESSAGE_FLAG);
export const showReferrals = getBooleanValue(process.env.REACT_APP_SHOW_REFERRALS);
export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

export const allowPatientCreation = getBooleanValue(process.env.REACT_APP_ALLOW_PATIENT_CREATION);

export const timerCheckFocus = 60000 * 10 //in miliseconds
export const timerWaitAnswer = 60000 //in miliseconds

export const calculateAge = dob => {
  const birthDate = new Date(dob)
  const difference = Date.now() - birthDate.getTime()
  const age = new Date(difference)

  return Math.abs(age.getUTCFullYear() - 1970)
}

function getBooleanValue(envValue) {
  if (!envValue) {
    return false;
  }
  if (typeof envValue === 'string') {
    return envValue.trim().toLowerCase() === 'true';
  }
  return envValue === true;
}

export const DEVELOPMENT_DB_USERNAME = 'arturo.escalante@nicheaim.com';
